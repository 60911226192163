import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import BetaPage from './BetaPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <BetaPage />
  // <Router>
  //   <Auth0Provider
  //     domain={process.env.REACT_APP_AUTH0_DOMAIN}
  //     clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
  //     authorizationParams={{
  //       redirect_uri: window.location.origin
  //     }}
  //   >
  //     <App />
  //   </Auth0Provider>
  // </Router>
  //</React.StrictMode>
);