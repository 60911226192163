import React from 'react'
import { Button } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { purple } from '@mui/material/colors';

const BetaPage = () => {
    return (
        <main className="flex flex-col place-content-center min-h-screen min-w-screen items-center">
            <h1 className="text-7xl">mycronation</h1>
            <h1 className="text-3xl py-3">governance automation software for your micronation</h1>
            <h1 className="text-2xl py-3">what does that mean?</h1>
            <ul className="list-disc">
                <li className='text-xl'>manage your citizens from within the app and control who joins, safely and securely</li>
                <li className='text-xl'>control and automate the composition of your government, and have us manage their powers</li>
                <li className='text-xl'>schedule elections and ensure only your citizens vote.</li>
                <li className='text-xl'>automate the flow and transfer of power according to election results</li>
                <li className='text-xl'>in the future: create a treasury and control currency circulation, interest rates, and allow your citizens
                    to create their own industry.
                </li>
                <li className='text-xl'>and much more!</li>
            </ul>
            <h1 className="text-2xl py-3">we're still in development, but please join our discord server to
                stay up to date on development or ask any questions:
            </h1>
            <ThemeProvider theme={() => createTheme({
                palette: {
                    primary: {
                        main: purple[900]
                    }
                }
            })}>
                <Button sx={{ fontSize: "30px" }} className="p-5" variant="contained"
                    onClick={() => {
                        window.location.href = 'https://discord.gg/j7dTd7MzvE';
                    }}
                >
                    Discord
                </Button>
            </ThemeProvider>


        </main >
    )
}

export default BetaPage